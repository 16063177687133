export const environment = {
  production: false,
  apiKey: '37a59818-ee25-4f8a-bf5f-75830489890a',
  secretKey: 'zdMHhKUvjA6eEEv9XMCmxfG87VdckUdAxqTyAZUunEfG6rA2skF6KMGMuje3A2nh',
  initial: {
    loginPath: '/',
    mainPath: '/ms/ads-campaign',
  },
  cache: {
    isEncrypt: false,
  },
  appConfig: {
    apiCallDelay: 1000,
    routerLinkDelay: 800,
  },
  backendServer: { mainApi: 'https://apis-dev-xrb.monkgames.net' },
  authentication: {
    login: 'v1/auth/signin-adsmanager',
    logout: 'v1/auth/signout',
    refreshToken: 'v1/auth/refreshtoken',
    resetPassword: 'v1/auth/resetpass',
  },
  areaEndpoints: {
    crud: 'user-backend/area',
    listPaging: '',
    addCampaign: '',
  },
  msBillboard: {
    crud: 'ms-billboard',
    listPaging: 'ms-billboard',
  },
  billboard: {
    crud: 'billboard',
    listPaging: 'billboard',
  },
  adsCampaign: {
    crud: 'ads-campaign',
    listPaging: 'ads-campaign',
  },
  adsMedia: {
    crud: 'ads-media',
    listPaging: 'ads-media',
  },
  userBackend: {
    crud: 'user-backend',
    listPaging: 'user-backend',
  },
  userPolicy: {
    crud: 'user-policy',
    listPaging: 'user-policy',
    area: 'user-policy/area',
  },
  userBrand: {
    crud: 'user-brand',
    listPaging: 'user-brand',
  },
};
