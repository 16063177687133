<div [ngClass]="{
    'flex-column': item.layout === 'COLUMN',
    'flex-row align-items-center': item.layout === 'ROW'
  }" class="d-flex justify-content-between my-3" *ngFor="let item of data">
  <div class="label-bold">{{ item.label }}</div>
  <ng-container *ngIf="item.static; else elseTemplate">
    <ng-container *ngIf="item.type === 'time'; else normalText">
      <div>{{ item.value | customtime }}</div>
    </ng-container>
    <ng-template #normalText>
      <div>{{ item.value }}</div>
    </ng-template>
  </ng-container>
  <ng-template #elseTemplate>
    <div>
      <input class="ant-input" *ngIf="item.type === 'text'" [type]="item.type" [(ngModel)]="item.value"
        [disabled]="!enableEdit" (ngModelChange)="onDataChange(item)" />

      <textarea *ngIf="item.type === 'textarea'" nz-input [placeholder]="item.ctrlPlaceholder"
        [nzAutosize]="{ minRows: 2, maxRows: 6 }" [(ngModel)]="item.value" [disabled]="!enableEdit"
        (ngModelChange)="onDataChange(item)">{{ item.value }}</textarea>

      <nz-input-number *ngIf="['number'].includes(item.type)" [(ngModel)]="item.value" [nzStep]="1"
        [nzDisabled]="!enableEdit" (ngModelChange)="onDataChange(item)"></nz-input-number>

      <nz-select *ngIf="item.type === 'select' && item.optionSelector.length > 0" [nzPlaceHolder]="item.value" [nzDisabled]="!enableEdit"
        [(ngModel)]="item.value" (ngModelChange)="onDataChange(item)">
        <nz-option *ngFor="let selector of item.optionSelector" [nzValue]="selector.value" [nzLabel]="selector.name">
        </nz-option>
      </nz-select>

      <nz-switch *ngIf="item.type === 'boolean'" nzSize="small" [(ngModel)]="item.value" [nzDisabled]="!enableEdit"
        (ngModelChange)="onDataChange(item)"></nz-switch>
    </div>
  </ng-template>
</div>