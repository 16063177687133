<header class="d-flex">
  <!-- Left Section -->
  <div class="text-site-name">XRB Advertising</div>

  <!-- Right Section -->
  <div class="header-right-panel">
    <nz-avatar style="background-color: #87d068" nz-dropdown nzTrigger="click" [nzDropdownMenu]="menu">
    </nz-avatar>

    <nz-dropdown-menu #menu="nzDropdownMenu">
      <ul nz-menu>
        <li nz-menu-item (click)="onLogout()">
          <ng-icon name="matLogoutSharp" size="15px" class="ng-icon-alignment me-2"></ng-icon>
          Logout
        </li>
      </ul>
    </nz-dropdown-menu>
  </div>
</header>

<div class="mobile-nav-body" [ngClass]="{ 'active fadeIn': openSideMenu }">
  <div class="mobile-nav-wrapper main-menu-wrap" [ngClass]="{ 'active fadeIn': openSideMenu }">
    <div class="close_bar pointer" (click)="onToggleSideMenu()">
      <ng-icon name="matCloseSharp" size="15px" class="ng-icon-alignment me-2"></ng-icon>
    </div>

    <div class="mobile-sidebar">
      <div class="top-path">
        <ul>
          <li>
            <a routerLink="/ms/map-view" class="nav-link"><span class="title-link">Dashboard</span></a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>

<div class="dashboard openFullBar padd">
  <div class="sidebar dash-side d-none d-lg-flex" style="height: calc(100% - 43px);">
    <div class="top-path">
      <ul nz-menu nzMode="vertical" style="width: 200px">
        <li nzMatchRouter nzMatchRouterExact routerLink="/ms/map-view" nz-menu-item>
          Map View
        </li>

        <li nzMatchRouter nzMatchRouterExact routerLink="/ms/ads-campaign" nz-menu-item>
          Ads Campaigns
        </li>

        <li nzMatchRouter nzMatchRouterExact routerLink="/ms/ads-media" nz-menu-item>
          Ads Media
        </li>

        <li nzMatchRouter nzMatchRouterExact routerLink="/ms/billboard" nz-menu-item>
          Billboards
        </li>
      </ul>
    </div>
  </div>

  <div class="content-wrapper">
    <router-outlet></router-outlet>
  </div>
</div>