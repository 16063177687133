import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppGuard } from './guards/app.guard';
import { AuthGuard } from './guards/auth.guard';
import { PageNotfoundComponent } from './modules/pageview/contents/public-contents/page-notfound/page-notfound.component';
import { AuthenComponent } from './modules/pageview/layout/authen/authen.component';
import { PublicComponent } from './modules/pageview/layout/public/public.component';

const PUBLIC_ROUTES: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        './modules/pageview/contents/public-contents/public-contents.module'
      ).then((m) => m.PublicContentsModule),
  },
];

const AUTHENTICATED_ROUTES: Routes = [
  {
    path: '',
    canActivate: [AppGuard],
    loadChildren: () =>
      import(
        './modules/pageview/contents/authen-contents/authen-contents.module'
      ).then((m) => m.AuthenContentsModule),
  },
];

const routes: Routes = [
  {
    path: '',
    component: PublicComponent,
    children: PUBLIC_ROUTES,
  },
  {
    path: '',
    component: AuthenComponent,
    children: AUTHENTICATED_ROUTES,
  },
  {
    path: '**',
    component: PageNotfoundComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      anchorScrolling: 'enabled',
      scrollPositionRestoration: 'enabled',
      initialNavigation: 'enabledBlocking',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
